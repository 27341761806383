<template>
  <v-container>
    <ValidationObserver ref="form" v-slot="{ invalid }">
      <v-row v-if="community">
        <v-col cols="12" sm="10" lg="8">
          <h4 class="title">{{ community.id ? 'Edit' : 'New' }} Community</h4>
        </v-col>
        <v-col cols="12" sm="10">
          <v-card class="mx-auto" outlined>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="8">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Title"
                    rules="required|min:10"
                    immediate
                  >
                    <v-text-field
                      v-model="community.title"
                      label="Title"
                      :error-messages="errors"
                      hint="Community Name, (e.g. Black Food Experience)"
                      persistent-hint
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="6" md="4" class="title">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Title"
                    rules="required|min:3"
                    immediate
                  >
                    <v-text-field
                      v-model="community.name"
                      label="Community Code"
                      persistent-hint
                      :error-messages="errors"
                      hint="A unique code. (e.g. bfe)"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row align="center" v-if="operatingCities">
                <v-col cols="8"> </v-col>
                <v-col cols="4">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="City"
                    rules="required"
                    immediate
                  >
                    <v-select
                      v-model="city_id"
                      :items="operatingCities"
                      append-outer-icon="mdi-office-building-marker"
                      item-text="city_name"
                      item-value="id"
                      hide-details
                      :error-messages="errors"
                      label="Operating City"
                      single-line
                      @change="copyLocation"
                    ></v-select>
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="8">
                  <v-text-field
                    v-model="community.external_link"
                    label="External Link"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="community.uid"
                    label="Admin"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col cols="12">
                  <v-textarea
                    v-model="community.description"
                    rows="3"
                    label="Description"
                  ></v-textarea>
                </v-col>
              </v-row>

              <v-row v-if="id">
                <v-col cols="12" sm="6" v-if="community.photo">
                  <v-img :src="community.photo"></v-img>
                </v-col>
                <v-col cols="12" sm="6">
                  <media-uploader
                    @uploadMedia="uploadMedia"
                    uploadType="media"
                  />
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions>
              <v-btn color="info" :disabled="invalid" @click.stop="save"
                >{{ community.id ? 'Update' : 'Save' }}
              </v-btn>

              <v-spacer></v-spacer>
              <v-btn to="/admin/communities/list"> Back </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="id">
        <v-col cols="12">
          <CommunityMembers :id="id" />
        </v-col>
      </v-row>

      <v-snackbar color="green" v-model="snackbar" :timeout="2000">
        The community information was
        {{ community && community.id ? 'updated' : 'saved' }} successfully.

        <template v-slot:action="{ attrs }">
          <v-btn color="green" text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </ValidationObserver>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {
    MediaUploader: () =>
      import('fod-core/src/components/common/MediaUploader.vue'),
    CommunityMembers: () => import('./CommunityMembers.vue')
  },
  data() {
    return {
      id: null,
      uid: null,
      community: null,
      snackbar: false,
      city_id: null
    }
  },
  computed: {
    ...mapGetters({
      loading: 'loading',
      profile: 'profile',
      operatingCities: 'operatingCities'
    })
  },
  async mounted() {
    await this.$store.dispatch('getOperatingCities')
    if (this.$route.params.id) {
      this.id = this.$route.params.id
      console.log('Loading Community:', this.$route.params.id)
      this.$store
        .dispatch('loadCommunity', { id: this.$route.params.id })
        .then(cData => {
          console.log(cData, 'cdata')
          this.city_id = cData.city_id
          this.community = { id: this.$route.params.id, ...cData }
        })
    } else {
      this.community = {
        title: '',
        name: '',
        external_link: '',
        description: '',
        city_code: ''
      }
    }
  },

  methods: {
    save() {
      console.log(this.community, 'community')
      this.$store.dispatch('saveCommunity', this.community).then(() => {
        this.snackbar = true
      })
    },
    copyLocation(item) {
      const city = this.operatingCities.find(o => o.id === item)
      if (city) {
        // this.community.city_code = city.city_code
        this.community.city_id = city.id
        this.community.city_code = city.city_code
        this.community.location = city.location
      }
    },

    uploadMedia(file, img) {
      const ext = file.name.slice(file.name.lastIndexOf('.'))
      this.$store
        .dispatch('uploadFile', {
          file,
          type: 'community',
          id: this.profile.uid,
          key: `${this.community.name}${ext}`
        })
        .then(path => {
          this.community.photo = path
          this.$store.dispatch('saveCommunity', this.community).then(() => {
            this.snackbar = true
          })
        })
      /* this.$store.dispatch('uploadMedia', {
        uid: this.uid,
        file: file,
        img: img,
        type: 'community'
      }) */
    }
  }
}
</script>
